<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>Add New User</h4>
                </div>
            </div>
            <vs-divider class="mb-0" />
            <div class="vx-col w-full mb-base">
                <vx-card no-shadow>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(saveCompany)">
                            <div class="vx-row mb-6">
                                <div class="vx-col md:w-1/2 w-full">
                                    <ValidationProvider name="firstname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" label="First name" name="firstname" v-model="currentUser.firstname" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="vx-col md:w-1/2 w-full">
                                    <ValidationProvider name="lastname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" label="Last name" name="lastname" v-model="currentUser.lastname" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <ValidationProvider name="Email" :rules="{ required: true, email: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" label="Email" v-model="currentUser.emailAddress" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <ValidationProvider class="w-full" name="Phone" :rules="{ regex: /^[0-9]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" label="Phone" name="Phone" v-model="currentUser.mobileNumber" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <ValidationProvider class="w-full" name="Phone" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-select  v-model="currentUser.userType" name="userType" class="w-full" placeholder="Select Role">
                                            <vs-select-item v-for="(item, index) in userRole" :key="index" :value="item" :text="item" />
                                        </vs-select>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <vs-divider />
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-button class="mr-3 mb-2" @click.prevent="saveUser">Submit</vs-button>
                                    <vs-button class="mr-3 mb-2" color="dark" @click="closeWindow">Cancel</vs-button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </vx-card>
            </div>
        </div>
    </vs-sidebar>
</div>
</template>

<script>
import {
    userData
} from '../../store/api/user';

import {
    customMessages
} from './../../filters/validationmessage'

export default {
    props: {
        openAddWindow: {
            type: Boolean,
            required: true,
            default: false,
        },
        selectedUser: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            currentUser: {},
            active: {},
            customMessages,
            userRole:[
              "Administrator",
              "CaseOfficer"
            ]
        };
    },
    watch: {
        openAddWindow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
        selectedUser: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.currentUser = newValue;
            }
        }
    },
    methods: {
        async saveUser() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await userData.createUser(this.currentUser);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "User added",
                color: "success",
                position: "top-center"
            });

            this.$emit("loadUsers");
        },
        closeWindow() {
            this.$emit("loadUsers");
        }
    }
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
